<template>
  <b-modal title="Add Client" size="lg" v-model="active">
    <b-row class="form-group">
      <b-col md="6">
        <label>First Name</label>
        <b-form-input type="text" v-model.trim="client.primaryContact.firstName" :state="state($v.client.primaryContact.firstName)" @input="touch($v.client.primaryContact.firstName)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
      <b-col md="6">
        <label>Last Name</label>
        <b-form-input type="text" v-model.trim="client.primaryContact.lastName" :state="state($v.client.primaryContact.lastName)" @input="touch($v.client.primaryContact.lastName)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col md="6">
        <label>E-Mail Address</label>
        <b-form-input type="text" placeholder="" v-model.trim="client.primaryContact.emailAddress.address" :state="state($v.client.primaryContact.emailAddress.address)" @input="touch($v.client.primaryContact.emailAddress.address)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row v-if="existingClients.length > 0">
      <b-col sm="12">
        <p><strong>One or more existing clients have the same email address.  Click the row below to go to the client, or add anyway.</strong></p>
        <b-table hover :small="true" :items="existingClients" :fields="tableFields" @row-clicked="goToClient"></b-table>
      </b-col>
    </b-row>
    <div v-if="existingClients.length > 0" slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="warning" @click="saveAnyway()">
        Add Client Anyway
      </b-btn>
    </div>
    <div v-if="existingClients.length == 0" slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="save()">
        Add Client
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators"

export default {
  name: 'AddClientModal',
  data () {
    return {
      tableFields: [
        { key: 'primaryContact.firstName', label: 'First Name' },
        { key: 'primaryContact.lastName', label: 'Last Name' },
        { key: 'primaryContact.emailAddress.address', label: 'E-Mail' },
        { key: 'organization.name', label: 'Organization' },
      ],
      client: {
        primaryContact: {
          firstName: null,
          lastName: null,
          emailAddress: {
            address: null
          }
        }
      },
      active: false,
      existingClients: []
    }
  },
  methods: {
    goToClient (client) {
      this.$router.push({ name: 'Client', params: { id: client.id }})
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      this.existingClients = []
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (client) {
      this.active = true
      if (client) {
        this.client = { ...client }
      } else {
        this.client = {
          primaryContact: {
            firstName: null,
            lastName: null,
            emailAddress: {
              address: null
            }
          }
        }
      }
    },
    save () {
      this.existingClients = []
      this.$api.clients.findByEmail({ query: this.client.primaryContact.emailAddress.address })
        .then((response) => {
          this.existingClients = response.data
          if (this.existingClients.length === 0) {
            let client = this.client
            this.$emit('save', { ...client })
            this.active = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    saveAnyway () {
      this.existingClients = []
      let client = this.client
      this.$emit('save', { ...client })
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:addClient', (client) => {
      this.show(client);
      this.$nextTick(() => { this.$v.$reset() })
    })
  },
  validations: {
    client: {
      primaryContact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        emailAddress: {
          address: {
            required
          }
        }
      }
    }
  }
}
</script>
